<template>
	<SubtypesServicePermissions />
</template>
<script>
import SubtypesServicePermissions from '@/components/permissions/subtypesService/index.vue';

export default {
	name: 'SubtypesServicePermissionsPage',
	components: { SubtypesServicePermissions },
};
</script>
