<template>
	<sub-types-permissions />
</template>
<script>
import SubTypesPermissions from '@/components/permissions/subtypes/index.vue';

export default {
	name: 'SubTypesPermissionsPage',
	components: { SubTypesPermissions },
};
</script>
