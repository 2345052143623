import { mapActions } from 'vuex';

export default {
	name: 'DeleteSubtypeDialog',
	data() {
		return {
			loadingSave: false,
			listError: [],
		}
	},
	props: {
		value: Boolean,
		item: Object,
	},
	computed: {
		dialog: {
			get() {
				this.listError = [];
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	methods: {
		...mapActions('subtype', ['deleteSubtype']),
		close() {
			this.dialog = false;
			this.listError = [];
		},
		async deleteItemConfirm() {
			if (this.item) {
				this.loadingSave = true;
				const { ok, error } = await this.deleteSubtype(this.item.id);

				if (ok) {
					this.close();
					this.$emit('closeDialog');
				} else {
					this.listError = Array.isArray(error?.data?.message)
						? error?.data?.message
						: [error?.data?.message] || [];
					this.loadingSave = false;
				}
				this.loadingSave = false;
			}
		},
	},
};
