import { mapActions, mapState } from "vuex";

export default {
  name: "CreateSubtypeDialog",
  data() {
    return {
      valid: false,
      loadingSave: false,
      listError: [],
      subTypeForm: {
        name: "",
        classification: null,
        parent: null,
        company_id: null,
        responsible_id: null,
        //
        flag_file: true,
        flag_comment: true,
        flag_observe_asset: true,
        flag_required_asset:false
      },
      nameRules: [
        (v) => !!v || "Nombre de Subtipo es requerido",
        (v) => v.length <= 50 || "El nombre debe tener menos de 50 carácteres",
      ]
    };
  },
  props: {
    value: Boolean,
    item: Object,
    parent: Object,
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    ...mapState({
      companies: (state) => state.auth.user?.companies,
    }),
    ...mapState('user',['responsibles']),
  },
  created() {
    //this.getUsersActive();
  },
  methods: {
    ...mapActions("subtype", ["createSubtype"]),
    //...mapActions("security", ["getUsersActive"]),
    async saveSubtype() {
      if (!this.$refs.form.validate()) {
        return;
      }
   
      if (this.parent && this.parent.type === 'subtype') {
        
        this.subTypeForm.parent = this.parent?.id;
        this.subTypeForm.classification = this.parent?.classification.id;
       
      } else if (this.parent.type === 'classification') {
        
        this.subTypeForm.classification = this.parent.id;
      } else {
    
        this.subTypeForm.parent = null;
      }
    
      const data = {
        ...this.subTypeForm,
        company_id: parseInt(localStorage.getItem("company_id")),
      };

      this.loadingSave = true;
      const { ok, error } = await this.createSubtype(data);
      if (ok) {
        this.dialog = false;
        await this.$emit('closeDialog');
        this.subTypeForm = {
          name: "",
          classification: null,
          parent: null,
          company_id: null,
          responsible_id: null,
          flag_file: true,
          flag_comment: true
        };
        this.$refs.form?.resetValidation();
      } else {
        this.listError = Array.isArray(error?.data?.message)
          ? error?.data?.message
          : [error?.data?.message] || [];
      }
      this.loadingSave = false;
    },

    dialogInput(value) {
      this.$nextTick(() => {
        //this.$refs.button.$el.blur();
        this.$refs.form.resetValidation();
        //this.dialog = value;
        if (!value) {
          this.subTypeForm = {
            name: "",
            classification: null,
            parent: null,
            company_id: null,
            responsible_id: null,
            flag_file: true,
            flag_comment: true,
            flag_observe_asset:true,
            flag_required_asset:false
          };
          this.listError = [];
        }
      })
    },

    close() {
      this.dialog = false;
      this.listError = [];
      this.subTypeForm = {
        name: "",
        classification: null,
        parent: null,
        company_id: null,
        responsible_id: null,
        flag_file: true,
        flag_comment: true,
        flag_observe_asset:true,
        flag_required_asset:false
      };
      this.$refs.form.resetValidation();
    },
  },
};