import { mapActions, mapState } from 'vuex';
import CreateSubtypeDialog from '../../elements/CreateSubtypeDialog';
import UpdateSubtypeDialog from '../../elements/UpdateSubtypeDialog';
import DeleteSubtypeDialog from '../../elements/DeleteSubtypeDialog';
import { hasItems, showError } from '@/helpers/globalHelpers.js';

export default {
	name: 'SubTypeTab',
	props: {
		value: Boolean,
		item: Object,
	},
	data() {
		return {
			tree: [],
			open: [],
			isLoading: false,
			createSubTypeItem: {},
			createSubTypeDialog: false,
			isDisabled: false,
			updateSubTypeItem: {},
			updateSubTypeDialog: false,
			deleteSubTypeItem: {},
			deleteSubTypeDialog: false,
			filters: {
				page: 1,
				limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 20,
				pagination: true,
				onTree: true,
				//q: '',
			},
		};
	},
	computed: {
		...mapState('area', [
			// 'areas',
			// 'areasPagination',
			'areaSubtypes',
			'areasSubtypePagination'
		]),
		// ...mapState("subtype", ["subtypesPagination"]),
		treeData() {
			const treeData = this.areaSubtypes.map((item) => this.transformData(item));

			this.open = this.getAllIds(treeData);
			return treeData;
		},
	},
	created() {
		this.getArea();
		this.getResponsibles();
	},
	methods: {
		...mapActions('area', [
			// 'listArea',
			// 'cleanAreas',
			'listAreaSubtype',
			'cleanAreasSubtype'
		]),
		...mapActions('user', ['getResponsibles']),
		hasItems,

		transformData(data) {
			let tree = {
				id: data.id,
				name: data.name,
				type: 'area',
				children: [],
			};

			if (data.classifications) {

				data.classifications.forEach((classification) => {
					let classificationNode = {
						id: classification.id,
						name: classification.name,
						type: 'classification',
						children: [],
						responsible: classification.responsible_id ? classification.responsible_id.fullName : 'Sin responsable', // Añadir el responsable aquí
					};

					if (classification.subtypes) {
						classification.subtypes.forEach((subtype) => {
							let subtypeNode = this.transformSubtypes(subtype);
							classificationNode.children.push(subtypeNode);
						});
					}

					tree.children.push(classificationNode);
				});
			}

			return tree;
		},

		transformSubtypes(subtype) {
		
			let subtypeNode = {
				id: subtype.id,
				name: subtype.name,
				type: 'subtype',
				children: [],
				classification: subtype.classification,
				responsible_id: subtype.responsible_id,
				flag_file: subtype.flag_file,
				flag_comment: subtype?.flag_comment,
				flag_observe_asset: subtype.flag_observe_asset,
				flag_required_asset: subtype?.flag_required_asset
			};

			if (subtype.children) {
				subtype.children.forEach((child) => {
					let childNode = this.transformSubtypes(child);
					subtypeNode.children.push(childNode);
				});
			}
			return subtypeNode;
		},

		create_SubType(item) {
		
			this.createSubTypeItem = { ...item, parent: item };
			this.createSubTypeDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		update_SubType(item) {

			this.updateSubTypeItem = Object.assign({}, item);
			this.updateSubTypeDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		delete_SubType(item) {
			this.deleteSubTypeItem = Object.assign({}, item);
			this.deleteSubTypeDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		async closeDialog() {
			if (this.areaSubtypes.length == 1 && this.filters.page > 1) {
				this.filters.page = this.filters.page - 1;
			}
			await this.getArea();
		},
		async getArea() {
			this.isLoading = true;
			const { ok, error } = await this.listAreaSubtype(this.filters);
			if (!ok) {
				showError(error);
			}
			this.isLoading = false;
		},
		getAllIds(data) {
			let ids = [];
			for (const item of data) {
				ids.push(item.id);
				if (item.children && item.children.length > 0) {
					ids = [...ids, ...this.getAllIds(item.children)];
				}
			}
			return ids;
		},
	},
	components: {
		CreateSubtypeDialog,
		UpdateSubtypeDialog,
		DeleteSubtypeDialog,
	},
};
