<template>
	<SubtypesServiceDetailPermissions />
</template>
<script>
import SubtypesServiceDetailPermissions from '@/components/permissions/elements/SubtypeServiceDetail/index.vue';

export default {
	name: 'SubtypesServiceDetailPermissionsPage',
	components: { SubtypesServiceDetailPermissions },
};
</script>
