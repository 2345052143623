import { mapActions, mapState } from 'vuex';

export default {
	name: 'UpdateSubtypeDialog',
	data() {
		return {
			//dialog: false,
			listError: [],
			valid: false,
			loadingSave: false,
			subTypeForm: {
				name: '',
				responsible_id: null,
				flag_file:false,
				flag_comment:false,
				flag_observe_asset:false,
				// flag_required_asset:false
			},
			nameRules: [
				(v) => !!v || 'Nombre de Subtipo es requerido',
				(v) => v.length <= 50 || 'El nombre debe tener menos de 50 carácteres',
			],
		};
	},
	props: {
		value: Boolean,
		item: Object,
	},
	watch: {
		dialog(newVal) {
			
			if (newVal) {
				this.subTypeForm = {
					name: this.item.name,
					responsible_id: this.item?.responsible_id?.id || null,
					flag_comment: this.item?.flag_comment,
					flag_file: this.item.flag_file,
					flag_observe_asset: this.item?.flag_observe_asset,
					flag_required_asset:this.item?.flag_required_asset
				};
			}
		},
	},
	computed: {
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		...mapState({
			companies: (state) => state.auth.user?.companies,
		}),
		...mapState('user',['responsibles']),
	},
	created() {
	
	},
	methods: {
		...mapActions('subtype', ['updateSubtype']),
	
		async saveSubtype() {
			if (!this.$refs.form.validate()) {
				return;
			}

			const data = {
				...this.subTypeForm,
			};

			this.loadingSave = true;
			const { ok, error } = await this.updateSubtype({
				id: this.item.id,
				data: data,
			});
			if (ok) {
				this.dialog = false;
				await this.$emit('closeDialog');
				this.subTypeForm = {
					name: '',
					classification: null,
					parent: null,
					company_id: null,
					responsible_id: null,
				};
				this.$refs.form?.resetValidation();
			} else {
				this.listError = Array.isArray(error?.data?.message)
					? error?.data?.message
					: [error?.data?.message] || [];
			}
			this.loadingSave = false;
		},

		close() {
			this.dialog = false;
			this.listError = [];
			this.subTypeForm = {
				name: '',
				classification: null,
				parent: null,
				company_id: null,
				responsible_id: null,
			};
			this.$refs.form.resetValidation();
		},
	},
};
