import { mapActions, mapState } from 'vuex';
import { showError } from '@/helpers/globalHelpers';

export default {
  name: 'SubtypeServiceComponent',

  // Propiedad 'data'
  data() {
    return {
      loading: false,
      headers: [
        {
          text: 'Nombre de Clasificación',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Tipo',
          align: 'start',
          sortable: false,
          value: 'type',
        },
        {
          text: 'Área',
          align: 'start',
          sortable: false,
          value: 'area.name',
        },
        {
          text: 'Responsable',
          align: 'start',
          sortable: false,
          value: 'responsible_id.fullName',
        },
        {
          text: 'Acciones',
          align: 'start',
          sortable: false,
          value: 'actions',
        },
      ],
      classificationFilters: {
        page: 1,
        limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 20,
        pagination: true,
      },
    };
  },

  created() {
    this.getClassificationService();
  },

  beforeDestroy() {
    this.cleanClassifications();
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: 'Inicio',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'Dashboard',
          },
        },
        {
          text: 'Permisos de Aplicación',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'PermissionsHome',
          },
        },
        {
          text: 'Servicio de subtipos',
          link: true,
          exact: true,
          disabled: true,
          // to: {
          // 	name: 'PermissionHomePage',
          // },
        },
      ];
    },
    ...mapState('classification',
      [
        'classifications',
        'classificationsPagination',
        'loadingClassification'
      ]
    ),
  },

  methods: {
    ...mapActions('classification', ['listClassification', 'cleanClassifications']),

    async getClassificationService() {
      this.loading = true;
      const { error } = await this.listClassification({ ...this.classificationFilters, type: 'SERVICIO' });
      if (error) showError(error);
      this.loading = false;
    },
  },

  components: {
  },
};