import SubTypeTab from "@/components/permissions/Tabs/SubtypesTab/SubTypeTab.vue";

export default {
	name: 'SubTypePermissionsContent',
	data: () => ({
		name: 'hello',
	}),
	computed: {
		breadcrumbs() {
			return [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Permisos de Aplicación',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'PermissionsHome',
					},
				},
				{
					text: 'Subtipos',
					link: true,
					exact: true,
					disabled: true,
					// to: {
					// 	name: 'PermissionHomePage',
					// },
				},
			];
		},
	},
	methods: {},
	components: { SubTypeTab },
};
